<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/edit/test?testTypeId=${$route.params.testTypeId}`
            "
          >
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${this.$route.params.sectionId}`
            "
          >
            {{ word.toUpperFirstLetter(sectionName) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="shortcutBtns">
      <router-link
        :to="
          `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}`
        "
      >
        <el-button type="primary">回到文章列表</el-button>
      </router-link>
      <router-link
        :to="
          `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/question`
        "
      >
        <el-button type="primary">回至題目列表</el-button>
      </router-link>
    </div>
    <div>
      <h1>
        {{ pageTitle }}
        <router-link
          :to="
            `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/${$route.params.passageId}/edit`
          "
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
      </h1>
      <div style="display: flex; align-items: center">
        <el-switch
          style="margin-right: 4px"
          v-model="isDisplaySingleQuestion"
          @change="changeToSingleQuestionMode"
          active-color="#42a16a"
          inactive-color="#42a16a"
          active-text="單題顯示"
          inactive-text="顯示該 Passage 所有題目"
        />
        <div
          v-if="isDisplaySingleQuestion"
          style="display: flex; flex-wrap: wrap"
        >
          <span
            :style="
              `
              background: ${
                question.order === Number($route.query.questionOrder)
                  ? '#42a16a'
                  : ''
              };
              color: ${
                question.order === Number($route.query.questionOrder)
                  ? '#fff'
                  : ''
              }
            `
            "
            class="questionOrder"
            v-for="question in passage.questions"
            :key="question.order"
            @click="enterToSingleQuestion(question.order)"
          >
            {{ question.order }}
          </span>
        </div>
      </div>
      <div v-if="passage" class="ivy-content">
        <div class="ivy-practice-bodyPage">
          <div class="view_questions">
            <el-row>
              <el-col :span="12">
                <div class="ivy-passage">
                  <ViewPassage
                    :key="question"
                    :passage="passage"
                    :type="$route.params.testType"
                    :section="sectionName"
                  />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="ivy-quesitons">
                  <Question
                    v-for="question in filterQuestion(passage.questions)"
                    :key="question.order"
                    :testType="$route.params.testType"
                    :testTypeId="$route.params.testTypeId"
                    :sectionId="$route.params.sectionId"
                    :question="question"
                    :disableDelete="false"
                    @reload="fecthPassage"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { word } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Question from "@/components/tests/Question.vue";
import ViewPassage from "@/components/tests/ViewPassage.vue";
import testsApi from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: { Breadcrumb, Question, ViewPassage },

  props: [],
  data() {
    return {
      isDisplaySingleQuestion: false,
      sectionName: "",
      testName: "",
      passage: {}
    };
  },
  computed: {
    pageTitle() {
      return `${this.passage.title || "Passage"} Questions`;
    },
    word() {
      return word;
    }
  },
  watch: {},

  async created() {
    if (this.$route.query.questionOrder) {
      this.isDisplaySingleQuestion = true;
    }
    await this.fecthPassage();
    const { full_name, subjects } = await testsApi.getTest(
      this.$route.params.testType,
      this.$route.params.testTypeId
    );
    this.testName = full_name;
    this.sectionName = subjects.find(
      subject => String(subject.id) === this.$route.params.sectionId
    ).name;
  },
  methods: {
    filterQuestion(questions) {
      if (!this.isDisplaySingleQuestion) return questions;
      return questions.filter(
        ({ order }) => order === Number(this.$route.query.questionOrder)
      );
    },
    changeToSingleQuestionMode(isDisplaySingleQuestion) {
      if (isDisplaySingleQuestion) {
        this.enterToSingleQuestion(this.passage.questions[0].order);
      }
    },
    enterToSingleQuestion(order) {
      this.$router.push({
        name: "EditPassage",
        params: {
          testType: this.$route.params.testType,
          testTypeId: this.$route.params.testTypeId,
          sectionId: this.$route.params.sectionId,
          passageId: this.$route.params.passageId
        },
        query: { questionOrder: order }
      });
    },
    async fecthPassage() {
      this.passage = await testsApi.getPassage(
        this.$route.params.testType,
        this.$route.params.passageId
      );
    }
  }
};
</script>

<style scoped>
.ivy-content {
  background: #fff;
  margin: 20px 0;
  padding: 10px 20px;
  border: 1px solid #dcdfe6;
}
.ivy-passage {
  height: calc(100vh - 330px);
  overflow: auto;
  padding-right: 20px;
}
.ivy-quesitons {
  height: calc(100vh - 330px);
  padding: 0 20px;
  overflow: auto;
}
.shortcutBtns > a {
  margin-right: 8px;
}
.questionOrder {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px 8px 0px;
  border: 1px solid #42a16a;
  border-radius: 4px;
  height: 28px;
  width: 28px;
}
.questionOrder:hover {
  color: #fff;
  background: #42a16a;
}
</style>
