var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"question"},[_c('el-card',{staticClass:"ivy-question",staticStyle:{"margin":"30px 0"},attrs:{"shadow":"hover"}},[_c('div',{staticClass:"question-content",staticStyle:{"margin-top":"10px"}},[(!_vm.disabledAction)?_c('div',[(!_vm.disableDelete)?_c('span',{staticStyle:{"float":"right","padding":"3px 0"},on:{"click":() => _vm.deleteQuestion(_vm.question.id)}},[_c('i',{staticClass:"fas fa-trash-alt warning-icon"})]):_vm._e(),_c('router-link',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"to":{
              name: 'EditQuestion',
              params: {
                testType: _vm.testType,
                testTypeId: _vm.testTypeId,
                sectionId: _vm.sectionId,
                questionId: _vm.question.id
              }
            }}},[_c('i',{staticClass:"fas fa-edit action-icon"})]),_c('router-link',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"to":{
              name: 'ViewQuestion',
              params: {
                testType: _vm.testType,
                testTypeId: _vm.testTypeId,
                sectionId: _vm.sectionId,
                questionId: _vm.question.id
              }
            }}},[_c('i',{staticClass:"fas fa-eye action-icon"})])],1):_vm._e(),_c('kbd',[_vm._v(_vm._s(_vm.question.order))]),_vm._v("   "),_c('b',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.question.content)}})])]),((_vm.question.tags || []).length > 0)?_c('p',[_c('b',[_vm._v("Topics: ")]),_vm._l((_vm.question.tags),function(tag){return _c('el-tag',{key:tag.id,staticClass:"ivy-tag",attrs:{"type":"success","effect":"dark"}},[_vm._v(_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, tag.name)))])})],2):_vm._e(),_c('div',{staticStyle:{"margin-bottom":"20px"}},[(
            _vm.question.type === 'default' ||
              _vm.question.type === 'multiple' ||
              _vm.question.type === 'click' ||
              _vm.question.type === 'checkbox' ||
              (_vm.question.type === 'math' && _vm.testType === 'act')
          )?_c('ul',{staticClass:"ivy-question-options"},_vm._l((_vm.question.options),function(option,index){return _c('li',{key:index,class:_vm.question.answers[0].answers.indexOf(option.letter) > -1
                ? 'option text-success'
                : 'option'},[_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.question.answers[0].answers.indexOf(option.letter) > -1
                  ),expression:"\n                    question.answers[0].answers.indexOf(option.letter) > -1\n                  "}],staticClass:"action-icon",staticStyle:{"display":"inline-block","width":"20px"}},[_c('i',{staticClass:"fas fa-check-circle"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                    !(_vm.question.answers[0].answers.indexOf(option.letter) > -1)
                  ),expression:"\n                    !(question.answers[0].answers.indexOf(option.letter) > -1)\n                  "}],staticClass:"action-icon",staticStyle:{"display":"inline-block","width":"20px"}}),_c('b',{staticStyle:{"margin-right":"4px"}},[_vm._v(_vm._s(`${option.letter}.`))]),_c('b',{staticClass:"optionText",domProps:{"innerHTML":_vm._s(option.title)}})])])}),0):_vm._e()]),(_vm.displayCorrectAnswer)?_c('el-collapse',{model:{value:(_vm.activeCorrectAnswer),callback:function ($$v) {_vm.activeCorrectAnswer=$$v},expression:"activeCorrectAnswer"}},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"padding":"12px","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("test.seeTheCurrectAnswerAndExp"))+" ")])]),_c('div',{staticClass:"ivy-exp"},[_c('el-alert',{staticStyle:{"font-size":"16px"},attrs:{"type":"success","closable":false}},[(_vm.question.exp !== '' && _vm.question.exp !== null)?_c('p',[_c('b',[_vm._v("Exp: ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.question.exp)}})]):_vm._e()])],1)],2)],1):_vm._e(),(_vm.question.passage && _vm.question.passage.id > 0 && !_vm.disabledAction)?_c('router-link',{staticClass:"passage-title",attrs:{"to":{
          name: 'EditPassage',
          params: {
            testType: _vm.testType,
            testTypeId: _vm.testTypeId,
            sectionId: _vm.sectionId,
            passageId: _vm.question.passage.id
          }
        }}},[_c('span',[_c('i',{staticClass:"fas fa-eye"}),_vm._v(" "+_vm._s(_vm.$t("test.seeTheQuestionPassage"))+" ")])]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }