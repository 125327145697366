<template>
  <div>
    <!-- <SatQuestionViewer
      v-if="testType === 'sat'"
      :disableDelete="false"
      :disabledAction="false"
      :displayCorrectAnswer="true"
      :displayOptions="displayOptions"
      :question="question"
      :editPath="
        $router.resolve({
          name: 'EditQuestion',
          params: {
            testType: testType,
            testTypeId: testTypeId,
            sectionId: sectionId,
            questionId: question.id
          }
        }).href
      "
      :viewPath="
        $router.resolve({
          name: 'ViewQuestion',
          params: {
            testType: testType,
            testTypeId: testTypeId,
            sectionId: sectionId,
            questionId: question.id
          }
        }).href
      "
      :passagePath="
        $router.resolve({
          name: 'EditPassage',
          params: {
            testType: testType,
            testTypeId: testTypeId,
            sectionId: sectionId,
            passageId: question.passage ? question.passage.id : 0
          }
        }).href
      "
      @onDeleteQuestion="questionId => deleteQuestion(questionId)"
    /> -->
    <div ref="question">
      <el-card class="ivy-question" shadow="hover" style="margin:30px 0">
        <div class="question-content" style="margin-top:10px">
          <div v-if="!disabledAction">
            <span
              v-if="!disableDelete"
              @click="() => deleteQuestion(question.id)"
              style="float: right; padding: 3px 0"
            >
              <i class="fas fa-trash-alt warning-icon" />
            </span>
            <router-link
              style="float: right; padding: 3px 0"
              :to="{
                name: 'EditQuestion',
                params: {
                  testType: testType,
                  testTypeId: testTypeId,
                  sectionId: sectionId,
                  questionId: question.id
                }
              }"
            >
              <i class="fas fa-edit action-icon" />
            </router-link>
            <router-link
              style="float: right; padding: 3px 0"
              :to="{
                name: 'ViewQuestion',
                params: {
                  testType: testType,
                  testTypeId: testTypeId,
                  sectionId: sectionId,
                  questionId: question.id
                }
              }"
            >
              <i class="fas fa-eye action-icon" />
            </router-link>
          </div>
          <kbd>{{ question.order }}</kbd> &nbsp;
          <b><span v-html="question.content"></span></b>
        </div>
        <p v-if="(question.tags || []).length > 0">
          <b>Topics: </b>
          <el-tag
            v-for="tag in question.tags"
            :key="tag.id"
            type="success"
            class="ivy-tag"
            effect="dark"
            >{{ helper.displayI18nText($i18n.locale, tag.name) }}</el-tag
          >
        </p>
        <div style="margin-bottom:20px">
          <ul
            class="ivy-question-options"
            v-if="
              question.type === 'default' ||
                question.type === 'multiple' ||
                question.type === 'click' ||
                question.type === 'checkbox' ||
                (question.type === 'math' && testType === 'act')
            "
          >
            <li
              :class="
                question.answers[0].answers.indexOf(option.letter) > -1
                  ? 'option text-success'
                  : 'option'
              "
              v-for="(option, index) in question.options"
              :key="index"
            >
              <div style="display: flex; align-items: flex-start">
                <span
                  class="action-icon"
                  style="display:inline-block;width:20px"
                  v-show="
                      question.answers[0].answers.indexOf(option.letter) > -1
                    "
                >
                  <i class="fas fa-check-circle"/>
                </span>
                <span
                  class="action-icon"
                  style="display:inline-block;width:20px"
                  v-show="
                      !(question.answers[0].answers.indexOf(option.letter) > -1)
                    "
                >
                </span>
                <b style="margin-right: 4px">{{ `${option.letter}.` }}</b>
                <b class="optionText" v-html="option.title"></b>
              </div>
            </li>
          </ul>
        </div>
        <el-collapse v-if="displayCorrectAnswer" v-model="activeCorrectAnswer">
          <el-collapse-item name="1">
            <template slot="title">
              <div style="padding: 12px; font-size: 16px">
                {{ $t("test.seeTheCurrectAnswerAndExp") }}
              </div>
            </template>
            <div class="ivy-exp">
              <el-alert type="success" :closable="false" style="font-size:16px">
                <p v-if="question.exp !== '' && question.exp !== null">
                  <b>Exp: </b>
                  <span v-html="question.exp"></span>
                </p>
              </el-alert>
            </div>
          </el-collapse-item>
        </el-collapse>
        <router-link
          class="passage-title"
          v-if="question.passage && question.passage.id > 0 && !disabledAction"
          :to="{
            name: 'EditPassage',
            params: {
              testType: testType,
              testTypeId: testTypeId,
              sectionId: sectionId,
              passageId: question.passage.id
            }
          }"
        >
          <span>
            <i class="fas fa-eye"></i>
            {{ $t("test.seeTheQuestionPassage") }}
          </span>
        </router-link>
      </el-card>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import testsApi from "@/apis/tests.js";

export default {
  props: {
    testType: {
      type: String,
      default: ""
    },
    testTypeId: {
      type: String,
      default: ""
    },
    sectionId: {
      type: String,
      default: ""
    },
    disableDelete: {
      type: Boolean,
      default: true
    },
    disabledAction: {
      type: Boolean,
      default: false
    },
    displayCorrectAnswer: {
      type: Boolean,
      default: true
    },
    displayOptions: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => ({
        id: null,
        order: null,
        content: "",
        options: [],
        tags: [],
        exp: ""
      })
    }
  },
  computed: {
    helper() {
      return helper;
    }
  },
  watch: {
    question() {
      if (this.$mathJax.isMathjaxConfig) {
        this.$mathJax.initMathjaxConfig();
      }
      this.$mathJax.MathQueue([this.$refs.question]);
    }
  },
  data() {
    return {
      activeCorrectAnswer: []
    };
  },
  mounted() {
    if (this.$mathJax.isMathjaxConfig) {
      this.$mathJax.initMathjaxConfig();
    }
    this.$mathJax.MathQueue([this.$refs.question]);
  },
  methods: {
    async deleteQuestion(questionId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await testsApi.deleteQuestion(this.testType, questionId);
        this.$message.success(this.$t("message.delete_success"));
        this.$emit("reload");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    getAnswer(answers) {
      let answer = "";
      answers[0].answers.forEach((item, index) => {
        if (item !== null) {
          if (index !== 0) {
            answer += "," + item;
          } else {
            answer = item;
          }
        }
      });
      return answer;
    }
  }
};
</script>

<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #ccc;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.passage-title {
  position: absolute;
  border: 1px solid #fefefe;
  background: #42a16a;
  font-size: 16px;
  color: white !important;
  left: -2px;
  top: -15px;
  height: 34px;
  padding: 0 17px;
  line-height: 32px;
  border-radius: 5px 5px 0 0;
}
.ivy-question {
  position: relative;
  overflow: visible;
  background-color: #f8f8f8;
}
.ivy-question .el-card {
  overflow: visible;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
.question-content {
  font-size: 16px;
}
.ivy-question-options {
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  font-size: 16px;
  border-bottom: solid 1px #e5e9ef;
  padding: 10px 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.question-tag {
  background: #f4f4f4;
  border-color: #f4f4f4;
  color: #777;
  margin: 5px;
  cursor: pointer;
}
.question-tag:hover {
  background: #43a06b;
  border-color: #43a06b;
  color: #fff;
}

.question_default {
  cursor: pointer;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  display: inline-block;
  background: #ddd;
  margin: 5px;
}

.question_id {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: 3px auto;
  background: #fff;
  text-align: center;
  line-height: 25px;
}

.question_success {
  background: #43a06b;
}
/* .ivy-question-options .option {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
} */
.ivy-exp >>> .el-alert__description {
  font-size: 16px;
}
.ivy-exp >>> .el-alert__title {
  font-size: 18px;
}

.optionText {
  display: inline-block;
}

.ivy-question >>> .optionText > p {
  margin-bottom: 0 !important;
}
</style>
